import { useEffect } from 'react'

function useScrollLock(enabled: boolean) {
    useEffect(() => {
        const style = document.body.style
        const locked = style.overflowY === 'hidden'
        const overflow = !locked && enabled ? 'hidden' : 'auto'

        setTimeout(
            () => {
                style.overflow = overflow
            },
            locked && enabled ? 300 : 0
        )

        return () => {
            style.overflow = 'auto'
        }
    }, [enabled])
}

export default useScrollLock
