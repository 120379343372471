import React, { useContext } from 'react'
import { AuthContext } from 'src/context/auth'
import { ButtonTrackClickType, EventType, GetButtonEventProperties, track } from '@/tracks'
import styles from './SideMenu.module.scss'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import {
    ArrowRightStartOnRectangleIcon,
    CalendarDaysIcon,
    HeartIcon,
    UserCircleIcon,
    XMarkIcon,
    DocumentTextIcon,
} from '@heroicons/react/24/outline'
import SQButton from '@/_shared/SQButton/SQButton'
import SQLink from '@/_shared/SQLink/SQLink'
import { useRouter } from 'next/navigation'
import useScrollLock from 'src/hooks/scrolllock'
import {
    trackLoginEnter,
    trackLoginFavorites,
    trackLoginMenu,
    trackLoginOffers,
    trackLoginVisits,
    trackLogout,
} from '@/auth/AuthIcon'
import { twMerge } from 'tailwind-merge'
import { trackSideMenuAdvertisePropertyClick, trackSideMenuBrokerClick } from '@/tracks/functions'
import { CouponBadge } from '@sq/components'
import { COUPON_CODE_DISCOUNT_CONTENT_URL } from 'src/constants'

interface SideMenuProps {
    variation?: 'user-area' | 'home' | 'search'
    isOpen: boolean
    onClose: () => void
}

export function SideMenu({ variation, isOpen, onClose }: SideMenuProps) {
    const { auth, logout, toggleLoginModal } = useContext(AuthContext)
    const router = useRouter()

    const loginClickHandler = (callback?: () => void) => {
        if (!auth.user) {
            trackLoginEnter()
            toggleLoginModal!()
        } else {
            trackLoginMenu()
            callback && callback()
            onClose()
        }
    }

    useScrollLock(isOpen)

    return (
        <div className={twMerge(styles.SideMenu, isOpen ? 'flex' : 'hidden')}>
            <div className={twMerge(styles.menu, isOpen && styles.open)}>
                <div className={styles.background} onClick={onClose} />
                <div className={styles.content}>
                    <button
                        onClick={onClose}
                        className={twMerge(
                            styles.header,
                            variation === 'search' ? styles.headerSearch : '',
                            'ml-auto'
                        )}
                    >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="size-6" />
                    </button>
                    <ul>
                        {auth.user ? (
                            <>
                                <li className={styles.logged}>
                                    <UserCircleIcon width={24} height={24} className="size-4" />
                                    <p className={styles.loginHeader}>{auth.user.name}</p>
                                </li>
                            </>
                        ) : (
                            <li className={styles.login}>
                                <p className={styles.loginHeader}>Entrar na minha área</p>
                                <p className={styles.loginDescription}>
                                    Crie e acesse sua conta para ver seus pontos comerciais
                                    favoritos e suas visitas
                                </p>
                                <SQButton
                                    size="small"
                                    onClick={() => {
                                        loginClickHandler()
                                        trackLoginEnter()
                                        onClose()
                                    }}
                                    className={styles.loginButton}
                                >
                                    Entrar
                                    <ArrowRightIcon className="size-4" />
                                </SQButton>
                            </li>
                        )}

                        <span className={styles.hr} />

                        <li
                            onClick={() =>
                                loginClickHandler(() => {
                                    trackLoginFavorites()
                                    router.push('/sua-conta/favoritos')
                                })
                            }
                            className={`${styles.accountLink} mb-4 mt-4`}
                        >
                            <HeartIcon className="size-6" /> Favoritos
                        </li>
                        <li
                            onClick={() =>
                                loginClickHandler(() => {
                                    trackLoginVisits()
                                    router.push('/sua-conta/visitas')
                                })
                            }
                            className={`${styles.accountLink} mb-4`}
                        >
                            <CalendarDaysIcon className="size-6" /> Visitas agendadas
                        </li>
                        <li
                            onClick={() =>
                                loginClickHandler(() => {
                                    trackLoginOffers()
                                    router.push('/sua-conta/propostas')
                                })
                            }
                            className={`${styles.accountLink} mb-4`}
                        >
                            <DocumentTextIcon className="size-6" /> Propostas
                        </li>

                        <span className={styles.hr} />

                        <li className={`${styles.link} mt-4`}>
                            <SQLink
                                className="flex justify-between"
                                href={'/imoveis/aluguel/all'}
                                onClick={onClose}
                            >
                                Buscar pontos comerciais
                                <ArrowRightIcon className="size-4" />
                            </SQLink>
                        </li>
                        <li className={`${styles.link}`}>
                            <SQLink
                                className="flex justify-between"
                                href={'/proprietario/anunciar-imovel'}
                                onClick={trackSideMenuAdvertisePropertyClick}
                            >
                                Para proprietários
                                <ArrowRightIcon className="size-4" />
                            </SQLink>
                        </li>
                        <li className={`${styles.link}`}>
                            <SQLink
                                className="flex justify-between"
                                href={'https://marketing.suaquadra.com.br/parceiro-sq'}
                                onClick={trackSideMenuBrokerClick}
                            >
                                Para corretores
                                <ArrowRightIcon className="size-4" />
                            </SQLink>
                        </li>
                        <li className={`${styles.link}`}>
                            <SQLink
                                className="flex justify-between"
                                href="/ajuda"
                                onClick={() => {
                                    track(
                                        EventType.ButtonClick,
                                        GetButtonEventProperties(
                                            'Nav - Ajuda',
                                            ButtonTrackClickType.FAQ
                                        )
                                    )
                                    onClose()
                                }}
                            >
                                Ajuda
                                <ArrowRightIcon className="size-4" />
                            </SQLink>
                        </li>
                        {auth.user && (
                            <li
                                className={styles.logout}
                                onClick={() => {
                                    trackLogout()
                                    logout()
                                }}
                            >
                                <ArrowRightStartOnRectangleIcon
                                    width={24}
                                    height={24}
                                    className="size-4"
                                />
                                Sair
                            </li>
                        )}
                        {auth.user?.coupon?.discount && (
                            <CouponBadge
                                discount={auth?.user?.coupon?.discount}
                                code={auth?.user?.coupon?.code}
                                link={COUPON_CODE_DISCOUNT_CONTENT_URL}
                                variant="inline"
                            />
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}
