import React, { useContext, useMemo } from 'react'
import { AuthContext } from 'src/context/auth'
import SQDropdown from '@/_shared/SQDropdown'
import SQDropdownItem from '@/_shared/SQDropdown/item'
import { useRouter } from 'next/navigation'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { EventType, GetButtonEventProperties, ButtonTrackClickType, track } from '@/tracks'
import SQSpinner from '@/_shared/SQSpinner/SQSpinner'
import { twMerge } from 'tailwind-merge'
import { CouponBadge } from '@sq/components'
import { COUPON_CODE_DISCOUNT_CONTENT_URL } from 'src/constants'

export const trackLoginEnter = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Login - Entrar', ButtonTrackClickType.Login)
    )

export const trackLoginMenu = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Login - Menu', ButtonTrackClickType.LoginMenu)
    )

export const trackLoginFavorites = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Login - favoritos', ButtonTrackClickType.Favorites)
    )

export const trackLoginVisits = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Login - visitas', ButtonTrackClickType.Visits)
    )

export const trackLoginOffers = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Login - propostas', ButtonTrackClickType.Offers)
    )

export const trackLogout = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Login - sair', ButtonTrackClickType.Logout)
    )

function AuthUserDropdown() {
    const { auth, logout } = useContext(AuthContext)
    auth.user?.coupon

    const label = useMemo(() => {
        if (!auth.loading && auth.user) {
            return auth.user.name?.split(' ')[0]
        }

        return null
    }, [auth])

    const router = useRouter()

    return (
        <SQDropdown
            label={<p className={'hidden sm:block'}>{label}</p>}
            rightIcon={<UserCircleIcon className="size-6" />}
        >
            <>
                <SQDropdownItem
                    onClick={() => {
                        trackLoginFavorites()
                        router.push('/sua-conta/favoritos')
                    }}
                >
                    Favoritos
                </SQDropdownItem>
                <SQDropdownItem
                    onClick={() => {
                        trackLoginVisits()
                        router.push('/sua-conta/visitas')
                    }}
                >
                    Visitas Agendadas
                </SQDropdownItem>
                <SQDropdownItem
                    onClick={() => {
                        trackLoginOffers()

                        router.push('/sua-conta/propostas')
                    }}
                >
                    Propostas
                </SQDropdownItem>
                <SQDropdownItem
                    onClick={() => {
                        trackLogout()
                        logout()
                    }}
                >
                    Sair
                </SQDropdownItem>
                {auth.user?.coupon?.discount && (
                    <div className="mx-4 mb-4 w-[150px]">
                        <CouponBadge
                            discount={auth.user.coupon.discount}
                            code={auth.user.coupon.code}
                            link={COUPON_CODE_DISCOUNT_CONTENT_URL}
                            variant="inline"
                        />
                    </div>
                )}
            </>
        </SQDropdown>
    )
}

type Props = {
    className?: string
}

export default function AuthIcon({ className }: Props = {}) {
    const { auth, toggleLoginModal } = useContext(AuthContext)

    const onClickHandler = () => {
        if (auth.auth) {
            trackLoginMenu()
        } else {
            trackLoginEnter()
            toggleLoginModal()
        }
    }

    return (
        <div
            className={twMerge(
                'flex flex-row items-center justify-center gap-2 text-base',
                auth.user && 'hover:cursor-pointer',
                className
            )}
            onClick={onClickHandler}
        >
            {auth.loading && <SQSpinner />}
            {!auth.loading && auth.user && <AuthUserDropdown />}
            {!auth.loading && !auth.user && (
                <>
                    <p className="hidden sm:block">Entrar</p> <UserCircleIcon className="size-6" />
                </>
            )}
        </div>
    )
}
