import { track, EventType, GetButtonEventProperties, ButtonTrackClickType } from '.'

export const trackBlogClick = () =>
    track(EventType.ButtonClick, GetButtonEventProperties('Blog', ButtonTrackClickType.Blog))

export const trackFAQClick = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Central de Ajuda', ButtonTrackClickType.FAQ)
    )

export const trackFooterAdvertisePropertyClick = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties(
            'Footer - Para Proprietários',
            ButtonTrackClickType.AdvertiseProperty
        )
    )

export const trackFooterBrokerClick = () => {
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Footer - Para Corretores', ButtonTrackClickType.Partners)
    )
}

export const trackFooterNeighborhoodClick = (neighborhood: string) =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties(`Footer Supply - ${neighborhood}`, ButtonTrackClickType.Footer)
    )

export const trackFooterTermsClick = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Termos de uso', ButtonTrackClickType.UseTerms)
    )

export const trackNavAdvertisePropertyClick = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Nav - Para Proprietários', ButtonTrackClickType.AdvertiseProperty)
    )

export const trackPrivacyPolicyClick = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Política de privacidade', ButtonTrackClickType.PrivacyPolicy)
    )

export const trackSideMenuAdvertisePropertyClick = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties(
            'Side menu - Para Proprietários',
            ButtonTrackClickType.AdvertiseProperty
        )
    )

export const trackSideMenuBrokerClick = () => {
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Side menu - Para Corretores', ButtonTrackClickType.Partners)
    )
}

export const trackSocialMediaClick = (socialMedia: string) =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties(socialMedia, ButtonTrackClickType.SocialMedia)
    )
