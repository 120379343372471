import React, { useRef, useState } from 'react'
import styles from './SQDropdown.module.scss'
import { useOnClickOutside } from 'usehooks-ts'

type SQDropdownOptions = {
    menuClasses?: string
}

interface SQDropdownProps {
    children: React.ReactElement
    rightIcon?: React.ReactElement
    label?: React.ReactElement
    options?: SQDropdownOptions
}

function SQDropdown({ children, label, rightIcon, options }: SQDropdownProps) {
    const [open, setOpen] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement>(null)
    useOnClickOutside(ref, () => setOpen(false))

    return (
        <div ref={ref} className={styles.SQDropdown}>
            <button
                className={styles.label}
                onClick={(e) => {
                    e.stopPropagation()
                    setOpen((v) => !v)
                }}
            >
                {label}
                {rightIcon && rightIcon}
            </button>
            <ul className={`${styles.menu} ${open ? styles.open : ''} ${options?.menuClasses}`}>
                {children}
            </ul>
        </div>
    )
}

export default SQDropdown
