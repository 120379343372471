import React, { ButtonHTMLAttributes } from 'react'
import styles from './SQButton.module.scss'

interface SQButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: string | React.ReactNode
    size?: 'small' | 'medium' | 'large'
    variant?: 'secondary' | 'tertiary'
    onClick?: React.MouseEventHandler
}

//SuaQuadra Button component
export default function SQButton(props: SQButtonProps) {
    if (props.variant === 'secondary') {
        return <SecondaryButton {...props} />
    }

    if (props.variant === 'tertiary') {
        return <TertiaryButton {...props} />
    }

    return <PrimaryButton {...props} />
}

interface GenericSQButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    onClick?: React.MouseEventHandler
    size?: 'small' | 'medium' | 'large'
    children: string | React.ReactNode
}

function PrimaryButton(props: GenericSQButtonProps) {
    const size = props.size ? styles[props.size] : styles['medium']
    return (
        <button
            {...props}
            className={`${styles.SQButtonPrimary} ${size} ${
                props.className !== undefined ? props.className : ''
            }`}
        >
            {props.children}
        </button>
    )
}

function SecondaryButton(props: GenericSQButtonProps) {
    const size = props.size ? styles[props.size] : ''
    return (
        <button
            {...props}
            className={`${styles.SQButtonSecondary} ${size}
             ${props.className !== undefined ? props.className : ''}`}
        >
            {props.children}
        </button>
    )
}

function TertiaryButton(props: GenericSQButtonProps) {
    const size = props.size ? styles[props.size] : styles['medium']
    return (
        <button
            {...props}
            className={`${styles.SQButtonTertiary}  ${size}
            ${props.className !== undefined ? props.className : ''}`}
        >
            {props.children}
        </button>
    )
}
